jQuery(document).ready(function ($) {

    /** =============================================================== **/
    /** Simplre select placeholder **/
    /** =============================================================== **/

    $(".form-select").change(function () {
        $(this).addClass('selected');
    });

    /** =============================================================== **/
    /** Menu toogler on mobile hamburger class change **/
    /** =============================================================== **/

    $(".hamburger").on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("is-active");
        $('body').toggleClass("overflow-hidden menu-opened");

    });

    /** =============================================================== **/
    /** Handle sticky menu on scroll **/
    /** =============================================================== **/

    $(window).on("scroll", function () {
        $("header").toggleClass("pinned", $(window).scrollTop() >= 50);
        // $("body").toggleClass("menu-pin-fix", $(window).scrollTop() >= 50);
    });
    $(window).trigger("scroll");

    /** =============================================================== **/
    /** Scroll to div **/
    /** =============================================================== **/

    $(".scrollto").click(function (event) {
        event.preventDefault();

        // $(".hamburger").removeClass("is-active");
        // $('body').removeClass("overflow-hidden menu-opened");

        var defaultAnchorOffset = 150;

        var anchor = $(this).attr('target');

        var anchorOffset = $('#' + anchor).attr('data-scroll-offset');
        if (!anchorOffset)
            anchorOffset = defaultAnchorOffset;

        $('html,body').animate({
            scrollTop: $('#' + anchor).offset().top - anchorOffset
        }, 200);
    });

    /** =============================================================== **/
    /** Slick slider **/
    /** =============================================================== **/

    if ($(".testimonial-slider").length) {
        $(".testimonial-slider").slick({
            dots: true,
            arrows: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 991.98,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
    ;


    if ($(".plan-images-slider").length) {
        $(".plan-images-slider").slick({
            dots: true,
            arrows: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            cssEase: 'linear'
        });
    }
    ;


    /** =============================================================== **/
    /** custom select script **/
    /** =============================================================== **/

    $(".custom-select2").each(function () {
        $(this).select2({
            placeholder: $(this).attr('placeholder'),
            minimumResultsForSearch: -1,
            theme: "custom-theme"
        });
    });


    /** =============================================================== **/
    /** tabs wrapper scroll to start depend on what button is clicked **/
    /** =============================================================== **/

    $('.tabs-wrapper .nav-tabs .nav-item .nav-link').on('click', function () {
        $('.tabs-wrapper').animate({scrollLeft: '+=' + ($(this).offset().left - 20)});
    })

    $('[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
        if ($(".plan-images-slider").length) {
            $(".plan-images-slider").slick('refresh');
        }
    });
});

/** =============================================================== **/
/** tooltip init **/
/** =============================================================== **/

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
});
