(function ($) {
    $(document).ready(function () {
        $(document).on('wpcf7mailsent', function (event) {
            const filteredInputs = event.detail.inputs.filter(input => {
                return (input.name === 'info-kit' || input.name === 'floor-plans-brochure');
            });
            if (filteredInputs.length) {
                const infoKitUrl = filteredInputs[0].value;
                if (infoKitUrl) {
                    const a = document.createElement('a');
                    a.style.display = "none";
                    a.href = infoKitUrl;
                    a.download = infoKitUrl.substring(infoKitUrl.lastIndexOf('/') + 1);
                    a.click();
                }
            }
        });

        // Phone Number Mask
        $(document).on('input', '#phone', function (e) {
            const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        });

    });
})(jQuery);
