/**
 * Loading required JS scripts and plugins such as jQuery and Bootstrap
 */

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

/**
 * Loading theme JS plugins
 */

// Slick slider
// http://kenwheeler.github.io/slick/
require('slick-carousel');

// Select2
// https://select2.org/
window.select2 = require('select2');


// custom script
require("./theme-shared");

// Form download
require("./form");
